import React from 'react';
import logo from '../images/soniqs-logo.png';

class Title extends React.Component{
    title = "Soniqs API Dashboard";
    subTitle = "Created by Jason Renner, Jordan Jaloudi, and Joe Reyes.";

    render() {
        return (
            <div className="sq-header">
                <img src={logo} className="sq-logo" alt="logo" />
                <h1>{this.title}</h1>
                {/* <h6>{subtitle}</h6> */}
            </div>
        );
    }
}

export default Title;