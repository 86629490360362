import swapClassForElement from "./swapClassForElement";


export default function activateMessageBox(color: string){
    var messageBox = document.getElementById("sq-import-message");
    if (messageBox) {
        swapClassForElement(messageBox, 'sq-login-active', 'sq-login-inactive');

        /* Change the text color based on whether we got an error message back or not. */
        messageBox.style.color = color;
    }
}