import CustomNavbar from "../components/nav";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import options from "../options/dropdownItems.json"
import React from "react";
import DropdownItems from "../interfaces/dropdownItems";
import { FileUploader } from "react-drag-drop-files";
import { getStorage, ref, uploadBytesResumable, } from 'firebase/storage';
import firebase from '../wrappers/firebase'
import getUserPermissions from "../wrappers/getUserPermissions";
import { useNavigate } from "react-router-dom";
import setActiveNav from "../wrappers/setActiveNav";

function Upload() {
    const {
        register,
        handleSubmit
    } = useForm({
        defaultValues: {
            information: ""
        }
    });

    const storage = getStorage(firebase);

    const [files, setFiles] = useState<File[]>([]);
    const [message, setMessage] = useState("");
    const [game, setGame] = useState<null | string>(null);
    const [gameTitle, setGameTitle] = useState<null | string>(null);
    const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
    const navigate = useNavigate();

    const onSubmit = (data: any) => {
        if (game === null || game === "") {
            setMessage("Please select a game before submitting the form.");
            return;
        }

        files.forEach((file: File) => {
            const storageRef = ref(storage, `/${game}/${file.name}`);

            /* Do the actual upload */
            uploadBytesResumable(storageRef, file) .catch((error) => {
                setMessage(`Could not upload ${file.name}: ${error}`)
            });
        })

        /* Display a message indicating a successful upload. */
        setMessage(`Uploaded ${files.length} file(s) to the database for ${gameTitle}.`);
        setFiles([]);
        setUploadedFiles([]);
    }

    const handleClick = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedGame = event.currentTarget.value;
        const selectedGameTitle: any = options.find((item: DropdownItems) => item.value === selectedGame)?.title;
        setGame(selectedGame === 'default' ? null : selectedGame);
        setGameTitle(selectedGame === 'default' ? null : selectedGameTitle);
    }

    const handleUpload = (selectedFiles: FileList) => {
        const selectedFile = Array.prototype.slice.call(selectedFiles)[0];
        const uploaded = [...files];
        uploaded.push(selectedFile);
        setFiles(uploaded);

        const fileNames = [...uploadedFiles];
        fileNames.push(selectedFile.name);
        setUploadedFiles(fileNames);
    }

    const fileTypes = ["zip"];

    const handleUploadError = (error: any) => {
        setMessage(error);
    }

    useEffect(() => {
        if(getUserPermissions('upload') === false){
            navigate('/Unauthorized');
        }

        setActiveNav('upload');
    }, [])

    return (
        <>
            <CustomNavbar />
            <div className='sq-app-container'>
                <div id='sq-app'>
                    <form className='sq-import-form' onSubmit={handleSubmit(onSubmit)}>
                        <div id='sq-upload'>
                            <select onChange={handleClick} id='sq-select-game' className='upload'>
                                <option key="default" value="default">Select a game</option>
                                {(options.length > 0 ? options.map((option: DropdownItems) => (
                                    <option key={option.value} title={option.title} value={option.value}>{option.title}</option>
                                )) : null)}
                            </select>
                            <FileUploader
                                multiple={true}
                                handleChange={handleUpload}
                                name="file"
                                fileTypes={fileTypes}
                                classes='upload'
                                onTypeError={handleUploadError}
                            />
                            <input id='sq-upload-button' className='sq-import-button upload' type="submit" value="Upload" />
                        </div>
                        <div id='sq-import-message' className='sq-upload-message'><p>{message}</p></div>
                        <div id='sq-uploaded-files'>
                            <table>
                                <tbody>
                                    <tr>
                                        <th colSpan={2}>Selected Files to Upload</th>
                                    </tr>
                                    {(uploadedFiles.length > 0 ? uploadedFiles.map((file: string) => (
                                        <tr key={file}>
                                            <td >{file}</td>
                                            <td className='sq-upload-delete'><input type='button' className='sq-import-button delete' value='Delete' /></td>
                                        </tr>
                                    )) : null)}
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Upload;