import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useLocation, useNavigate } from 'react-router-dom';
import DropdownItems from '../interfaces/dropdownItems';
import options from '../options/dropdownItems.json';
import React from 'react';

function NavDropdown() {
    const navigate = useNavigate();
    const location = useLocation();
    const [dropdownItems, setDropdownItems] = useState<DropdownItems[]>([]);

    const handleSelect = (event: any) => {
        const path = `/import/${event}`;
        if (location.pathname === path) {
            navigate(0);
        } else {
            navigate(path);
        }
    }

    useEffect(() => {
        if (location.pathname.includes('import') === true){
            document.getElementsByClassName('dropdown')[0].classList.add('sq-nav-active');;
        }

        const sortedItems = options.sort(
            function (a: DropdownItems, b: DropdownItems) {
                const valueA = a.title;
                const valueB = b.title;

                if (valueA < valueB) { return -1 }
                if (valueA > valueB) { return 1 }
                return 0;
            }
        )
        setDropdownItems(sortedItems);
    }, [])

    return (
        <DropdownButton className='sq-nav-button' id="dropdown-basic-button" title="Import" onSelect={handleSelect}>
            {(dropdownItems.length > 0 ? dropdownItems.map((option: DropdownItems) => (
                <Dropdown.Item eventKey={option.value} key={option.value} value={option.value}>{option.title}</Dropdown.Item>
            )) : null)}
        </DropdownButton>
    );
}

export default NavDropdown;