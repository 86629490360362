import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useLocation, useNavigate } from 'react-router-dom';
import AdminItems from '../interfaces/adminItems';
import options from '../options/listItems.json';
import React from 'react';

function AdminDropdown() {
    const navigate = useNavigate();
    const location = useLocation();
    const [AdminItems, setAdminItems] = useState<string[]>([]);

    const handleSelect = (event: any) => {
        let page = null;
        switch(event){
            case "Add User":
                page = "adduser";
                break;
            case "Update User":
                page = "updateuser";
                break;
            default:
                break;
        }

        const path = `/admin/${page}`;
        if (location.pathname === path) {
            navigate(0);
        } else {
            navigate(path);
        }
    }

    useEffect(() => {
        if (location.pathname.includes('admin') === true){
            document.getElementsByClassName('dropdown')[1].classList.add('sq-nav-active');
        }

        const adminOptions = options.find((item: AdminItems) => item.type === 'adminOptions');
        setAdminItems(adminOptions!.values);
    }, [])

    return (
        <DropdownButton className='sq-nav-button' id="dropdown-basic-button" title="Admin" onSelect={handleSelect}>
            {(AdminItems.length > 0 ? AdminItems.map((option: string) => (
                <Dropdown.Item eventKey={option} key={option} value={option}>{option}</Dropdown.Item>
            )) : null)}
        </DropdownButton>
    );
}

export default AdminDropdown;