import { Routes, Route, Navigate } from "react-router-dom";
import RocketLeagueComponent from "../pages/import/RocketLeague";
import SiegeComponent from "../pages/import/Siege";
import ValorantComponent from "../pages/import/Valorant";
import LoginComponent from "../pages/Login";
import StatsComponent from "./stats";
import ProtectedRoutes from "./protectedRoute";
import React from "react";
import UploadComponent from "../pages/Upload";
import DownloadComponent from "../pages/Download";
import ExportComponent from "../pages/ExportData";
import UnauthorizedComponent from "../pages/Unauthorized";
import AdminAddUser from "../pages/admin/AddUser";

function MainRoutes(){
    return (
        <Routes>
            <Route path='/stats' element={ <ProtectedRoutes><StatsComponent /></ProtectedRoutes>} />
            <Route path='/' element={ <ProtectedRoutes><StatsComponent /></ProtectedRoutes>} />
            <Route path='/login' element={<LoginComponent />} />
            <Route path='/Unauthorized' element={<UnauthorizedComponent />} />
            <Route path='/import' element={<ProtectedRoutes><SiegeComponent /></ProtectedRoutes>} />
            <Route path='/import/siege' element={<ProtectedRoutes><SiegeComponent /></ProtectedRoutes>} />
            <Route path='/import/rocketleague' element={<ProtectedRoutes><RocketLeagueComponent /></ProtectedRoutes>} />
            <Route path='/import/valorant' element={<ProtectedRoutes><ValorantComponent /></ProtectedRoutes>} />
            <Route path='/upload' element={<ProtectedRoutes><UploadComponent /></ProtectedRoutes>} />
            <Route path='/download' element={<ProtectedRoutes><DownloadComponent /></ProtectedRoutes>} />
            <Route path='/export' element={<ProtectedRoutes><ExportComponent /></ProtectedRoutes>} />
            <Route path='/admin/adduser' element={<ProtectedRoutes><AdminAddUser /></ProtectedRoutes>} />
            <Route path='/admin/updateuser' element={<ProtectedRoutes><AdminAddUser /></ProtectedRoutes>} />
        </Routes>
    )
}

export default MainRoutes;