import CustomNavbar from "../../components/nav";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import rocketLeagueLogo from "../../images/rocket-league-icon.png";
import React from "react";
import activateMessageBox from "../../wrappers/activateMessageBox";
import { useNavigate } from "react-router-dom";
import getUserPermissions from "../../wrappers/getUserPermissions";
import listItems from '../../options/listItems.json';
import { FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextField } from "@mui/material";

function RocketLeague() {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: {
            tier: "",
            region: "",
            information: "",
        }
    });

    const [message, setMessage] = useState<string | null>("");
    const [color, setColor] = useState<string | null>("");
    const [importForm, setImportForm] = useState("gameId");
    const [tier, setTier] = useState<any>('');
    const [tiers, setTiers] = useState<any>(null);
    const [region, setRegion] = useState<any>('');
    const [regions, setRegions] = useState<any>(null);
    const [startDate, setStartDate] = useState(`${new Date().getFullYear()}-01-01`);
    const [endDate, setEndDate] = useState(`${new Date().getFullYear()}-12-31`);
    const [preview, setPreview] = useState("Yes");
    const [matches, setMatches] = useState([]);

    //const baseURL = "http://localhost:8080";
    const baseURL = "https://soniqs-databank.uc.r.appspot.com";

    const onSubmit = (data: any) => {
        let message: string, color;

        if (importForm === "gameId") {
           importFromIDs(data);
        } else {
            importFromQuery();

        }
    }

    const triggerForm = (color: any, message: any) => {
        activateMessageBox(color);
        setMessage(message);
    }

    const importFromIDs = async (data: any) => {
        let rawInput = data.information;

        rawInput.trim();

        /* If the last value of the string is a comma, trim the string by one. */
        const lastChar = rawInput.charAt(rawInput.length - 1);
        if (lastChar === ","){
            rawInput = rawInput.substring(0, rawInput.length - 1);
        }

        if (!rawInput || rawInput === "") {
            setMessage(`Please enter at least one ID to import.`)
            setColor('red');
            triggerForm(color, message);
            return;
        } else {
            const values = (rawInput).split(",");
            const numberOfItems = values.length;
            setMessage(`Attempting to import ${numberOfItems} new record(s) into the database: ${values}`)
            setColor('black');
        }

        triggerForm(color, message);

        setMatches([]);
        setMessage("Retrieving matches...");
        activateMessageBox('black');

        await fetch(`${baseURL}/rocketleague/importMatches/${rawInput}`).then(response => response.json()).then(item => {
            if (item.status === 'error'){
                setMessage("Failure to import match: " + item.message);
                activateMessageBox('red');
                return;
            }

            /* if (typeof item === 'string') {
                setMessage("No matches found for the given parameters. Try a different query.");
                return;
            } */

            setMatches(item.results);
            setMessage(item.results.length + " match(es) retrieved and imported.");
        }).catch((error) => {
            setMessage(`Could not retrieve matches: ${error.message}`)
            activateMessageBox('red');
            console.log(error.message);
        });
    }

    const importFromQuery = async () => {
        if (tier === '' || region === '') {
            setMessage("Please select a Tier and a Region before submitting.")
            activateMessageBox('red');
            return;
        }

        setMatches([]);
        setMessage("Retrieving matches...");
        activateMessageBox('black');
        await fetch(`${baseURL}/rocketleague/getMatchesForQuery/${tier}/${region}/${startDate}/${endDate}/${preview}`).then(response => response.json()).then(item => {         
            if (typeof item === 'string') {
                setMessage("No matches found for the given parameters. Try a different query.");
                return;
            }

            setMatches(item);

            if (preview === 'Yes') {
                setMessage("Matches revieved. To import the below matches, set 'Preview Only' to 'No' and click Import.");
            } else {
                setMessage(item.length + " matches retrieved and imported.");
            }

            setPreview('Yes');
        }).catch((error) => {
            setMessage(`Could not retrieve matches: ${error.message}`)
            activateMessageBox('red');
            console.log(error.message);
        });
    }

    const navigate = useNavigate();
    useEffect(() => {
        if (getUserPermissions('rocketleague') === false) {
            navigate('/Unauthorized');
        }

        setTiers(listItems.find((item: any) => item.type === 'rlTiers'));
        setRegions(listItems.find((item: any) => item.type === 'rlRegions'));
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setImportForm((event.target as HTMLInputElement).value);
        setMessage("");
        setMatches([]);
    };

    const handleTier = (event: SelectChangeEvent) => {
        setTier(event.target.value);
    };

    const handleRegion = (event: SelectChangeEvent) => {
        setRegion(event.target.value);
    };

    const handleStartDate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setStartDate(event.target.value);
    };

    const handleEndDate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const currentDate = new Date(event.target.value);
        const newItem = new Date(currentDate.setDate(currentDate.getDate() + 2));
        var month: any = (newItem.getMonth() + 1);
        var day: any = newItem.getDate();

        /* If the leading zero is trimmed off, the API call breaks... */
        const formattedMonth = month < 10 ? month = "0" + month : month;
        const formattedDay = day < 10 ? day = "0" + day : day;
        
        const formattedDate = newItem.getFullYear() + '-' + formattedMonth + "-" + formattedDay;
        setEndDate(formattedDate);
    };

    const handlePreview = (event: SelectChangeEvent) => {
        setPreview(event.target.value);
    };

    return (
        <>
            <CustomNavbar />
            <div className='sq-app-container'>
                <div id='sq-app'>
                    <div id="select-game-div" className="sq-header">
                        <div>
                            <img id='sq-import-logo' src={rocketLeagueLogo} className="sq-logo" alt="logo" />
                        </div>

                        <div className='sq-import-form sq-import-text no-margin-top'>
                            <RadioGroup row defaultValue='gameId' name='radio-buttons-group' onChange={handleChange}>
                                <FormControlLabel value='gameId' control={<Radio key='key1' />} label="Import from Game ID's" />
                                <FormControlLabel value='range' control={<Radio key='key2' />} label="Import from Range of Games" />
                            </RadioGroup>
                        </div>

                        <form className='sq-import-form' onSubmit={handleSubmit(onSubmit)}>
                            {importForm === 'gameId' ? (
                                /* This form is for just importing from game ID values */
                                <div className='sq-import-form no-margin-top'>
                                    <input className='sq-import-input rocketleague' placeholder="ID0001,ID0002" {...register("information", { required: false })}></input>
                                    <input id='sq-import-button' className='sq-import-button sq-button-active' type="submit" value="Import" />
                                </div>
                            ) : (
                                /* This form is for importing from a range of games */
                                <div>
                                    <FormControl sx={{ m: 1, minWidth: 100 }}>
                                        <InputLabel id='sq-tier-select'>Tier</InputLabel>
                                        <Select labelId='sq-tier-select-label' id='sq-tier-select-helper' value={tier} label='Tier' onChange={handleTier}>
                                            {tiers.values.map((value: any) => (<MenuItem key={value} value={value}>{value}</MenuItem>))}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{ m: 1, minWidth: 100 }}>
                                        <InputLabel id='sq-region-select'>Region</InputLabel>
                                        <Select labelId='sq-region-select-label' id='sq-region-select-helper' value={region} label='Region' onChange={handleRegion}>
                                            {regions.values.map((value: any) => (<MenuItem key={value} value={value}>{value}</MenuItem>))}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{ m: 1, minWidth: 100 }}>
                                        <TextField
                                            id="sq-date-start"
                                            label="Start Date"
                                            type="date"
                                            defaultValue={startDate}
                                            onChange={handleStartDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>

                                    <FormControl sx={{ m: 1, minWidth: 100 }}>
                                        <TextField
                                            id="sq-date-end"
                                            label="End Date"
                                            type="date"
                                            defaultValue={endDate}
                                            onChange={handleEndDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>

                                    <FormControl sx={{ m: 1, minWidth: 100 }}>
                                        <InputLabel id='sq-tier-select'>Preview Only</InputLabel>
                                        <Select labelId='sq-tier-select-label' id='sq-tier-select-helper' value={preview} label='Preview Only' onChange={handlePreview}>
                                            <MenuItem key='previewYes' value='Yes'>Yes</MenuItem>
                                            <MenuItem key='previewNo' value='No'>No</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{ m: 1, minWidth: 100 }}>
                                        <input id='sq-import-button' className='sq-import-button sq-button-active rl-import' type="submit" value="Import" />
                                    </FormControl>
                                </div>
                            )}

                            <div id='sq-import-message' className='sq-login-inactive'>{message}</div>

                            {matches.length > 0 ? (
                                <div id='sq-uploaded-files'>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>Date</th>
                                                <th>Match Name</th>
                                            </tr>
                                            {(matches.length > 0 ? matches.map((match: any) => (
                                                <tr key={match.slug}>
                                                    <td>{match.date}</td>
                                                    <td>{match.name}</td>
                                                </tr>
                                            )) : null)}
                                        </tbody>
                                    </table>
                                </div>
                            ) : null}
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RocketLeague;