import { createContext } from "react";

const initialState = {
    files: [],
    readyToRender: false
};

export type DataState = typeof initialState;

const context = createContext<typeof initialState>(initialState);

export default context;