import CustomNavbar from "../../components/nav";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import siegeLogo from "../../images/siege-icon.png";
import React from "react";
import swapClassForElement from "../../wrappers/swapClassForElement";
import { useNavigate } from "react-router-dom";
import getUserPermissions from "../../wrappers/getUserPermissions";

function Siege() {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: {
            matchID: ""
        }
    });

    const [matchID, setMatchID] = useState("retrieving match id...");
    const [message, setMessage] = useState("");
    const [disabled, setDisabled] = useState(true);

    //const baseURL = "http://localhost:8080";
    const baseURL = "https://soniqs-databank.uc.r.appspot.com";
    
    /* Activates the message box underneath the buttons letting the user know something has happened they should be aware of. */
    const activateMessageBox = () => {
        var messageBox = document.getElementById("sq-import-message");
        if (messageBox){
            swapClassForElement(messageBox, 'sq-login-active', 'sq-login-inactive')

            /* Change the text color based on whether we got an error message back or not. */
            if (disabled){
                messageBox.style.color = 'red';
            }else{
                messageBox.style.color = 'black';
            }
        }
    }

    /* Handle how the Import Button is displayed while we retrieve the MatchID. */
    const readyForm = (enabled: boolean) => {
        const activeClass = 'sq-button-active';
        const inactiveClass = 'sq-button-inactive';
        const buttonId = 'sq-import-button';

        if (enabled){
            setDisabled(false);
            swapClassForElement(document.getElementById(buttonId)!, activeClass, inactiveClass)
        }else{
            setDisabled(true);
            swapClassForElement(document.getElementById(buttonId)!, inactiveClass, activeClass)
        }
    }

    /* Uses an API call to get the currently selected Match ID in Joe's spreadsheet. */
    const getMatchId = async () => {
        /* Set a default value while we fetch the actual value from the Database */
        setMatchID("Retrieving match ID...");
        readyForm(false);

        await fetch(`${baseURL}/getCurrentMatchId/siege`).then(response => response.json()).then(item => {
            var matchID = item.Response;

            if (matchID && matchID !== ""){
                setMatchID(matchID);
                readyForm(true);
            }else{
                readyForm(false);
            }
        }).catch((error) => {
            activateMessageBox();
            console.log(error);
            setMessage(`Error retrieving match ID: ${error.message}`);
        })
    }

    const navigate = useNavigate();
    useEffect(() => {
        if(getUserPermissions('siege') === false){
            navigate('/Unauthorized');
        }

        getMatchId();
    }, []);

    const onSubmit = async () => {
        const url = `${baseURL}/importmatch/siege`;
        await fetch(url).then(response => response.json()).then(item => {
            activateMessageBox();
            setMessage(item.Response);
        }).catch((error) => {
            activateMessageBox();
            setMessage(`Error retrieving match ID: ${error.message}`);
        })
    }

    return (
        <>
            <CustomNavbar />
            <div className='sq-app-container'>
                <div id='sq-app'>
                    <div id="select-game-div" className="sq-header">
                        <div>
                            <img id='sq-import-logo' src={siegeLogo} className="sq-logo" alt="logo" />
                        </div>
                        
                        <div className='sq-import-text'>Click the 'Import' button to import the match that corresponds to the Match ID below. Click the refresh button to update the match ID if you've changed it in the Import sheet.</div>

                        <form className='sq-import-form' onSubmit={handleSubmit(onSubmit)}>
                            <input className='sq-import-input' disabled={true} value={matchID} {...register("matchID", { required: false})}></input>
                            <button type="button" className="sq-import-refresh" title='Refresh the match id' onClick={getMatchId}><span className='reload'>&#x21bb;</span></button>
                            <input id='sq-import-button' className='sq-import-button sq-button-inactive siege' type="submit" value="Import" disabled={disabled}/>
                            <div id='sq-import-message' className='sq-login-inactive'>{message}</div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Siege;