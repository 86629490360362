import { useNavigate } from 'react-router-dom';

function getAuthStatus() {
    var expirationStorage = localStorage.getItem('expires');
    var expirationDate = null;
    var currentDate = new Date();

    if (expirationStorage === null){
        /* If no expiration date is found in the storage, it means the user hasn't logged in yet or something else happened, so automatically force them to the login page. */
        console.log("No expiration date found. Redirecting to login page...");
        return false;
    }else{
        console.log("Found expiration date:", expirationStorage);
        //expirationDate = new Date(parseInt(expirationStorage, 10));
        expirationDate = new Date(expirationStorage);

        console.log("Expiration Date:", expirationDate);
    } 

    /* If we have passed the allotted expiration date of one hour, then return false and we need to login again. */
    if (expirationDate <= currentDate){
        console.log("Expiration date has passed. Redirecting to login...");
        return false;
    }

    try{
        const user = JSON.parse(localStorage.getItem('user')!);
        console.log(user);
        return user.hasOwnProperty('email') ? true : false;
    }catch(e){
        console.log("local storage user not in the right format, redirecting to the login page...");
        return false;
    }
}

export default getAuthStatus;