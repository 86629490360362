import { useEffect, useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import PageTitle from "../components/title";
import { useForm } from 'react-hook-form';
import React from 'react';
import getAuthStatus from '../wrappers/getAuthStatus';
import setUserPermissions from '../wrappers/setUserPermissions';

const baseURL = "https://soniqs-databank.uc.r.appspot.com";
//const baseURL = "http://localhost:8080";

function Login() {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: {
            email: "",
            password: ""
        }
    });
    const auth = getAuth();
    const navigate = useNavigate();
    const [authing, setAuthing] = useState(false);
    const [errorMessage, setErrorMessage] = useState<null | string>(null);

    const onSubmit = (data: any) => {
        signIn(data.email, data.password);
    }

    const activateErrorBox = () => {
        var errorBox = document.getElementById("sq-login-errorbox");
        if (errorBox) {
            errorBox.classList.remove('sq-login-inactive');
            errorBox.classList.add('sq-login-active');
        }
    }

    const redirectToHomePage = () => {
        /* Navigate the user to the Home Page */
        navigate('/stats');
    }

    const setExpirationDate = () => {
        var today = new Date();
        var tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        localStorage.setItem('expires', tomorrow.toString());
    }

    const getUserFromDatabase = async (email: string) => {
        let user = null;
        console.log("Fetching user from database...");

        await fetch(`${baseURL}/getUserForLogin/${email}`).then(response => response.json()).then(item => {
            user = item;
        }).catch((error) => {
            console.log(error);
        });

        console.log(user);
        return user;
    }

    const emailSignin = (email: string, password: string) => {
        signInWithEmailAndPassword(auth, email, password)
            .then(async response => {
                const userEmail = response.user.email!;

                console.log("User Email:", userEmail);

                /* Store our user in local storage so we can set permissions accordingly. */
                const dbResponse: any = await getUserFromDatabase(userEmail);
                console.log("Response from database: " + dbResponse);

                if (dbResponse.status === 'error'){
                    setErrorMessage(dbResponse.message);
                    activateErrorBox();
                    setAuthing(false);
                    return;
                }
                localStorage.setItem('user', JSON.stringify(dbResponse.user));

                /* Create an expiration date set to one hour from now. After one hour, the user will be prompted to login again. */
                setExpirationDate();

                /* Determine whether or not the logged in user can see the import tab or not. */
                redirectToHomePage();
            })
            .catch((error) => {
                switch (error.code) {
                    case "auth/wrong-password":
                        setErrorMessage(`Invalid password. please try again.`)
                        break;
                    case "auth/invalid-email":
                        setErrorMessage(`Inavlid email address. Please try again.`);
                        break;
                    case "auth/user-not-found":
                        setErrorMessage(`No account with email address '${email}' exists. Please verify that's the correct email and try again.`);
                        break;
                    default:
                        setErrorMessage(`Could not login. Error code: ${error.code}`);
                        break;
                }
                activateErrorBox();
                setAuthing(false);
            })
    }

    const signIn = async (email: string, password: string) => {
        setAuthing(true);
        emailSignin(email, password);
    }

    useEffect(() => {
        /* If the user is redirected to the login page but has already logged in, just redirect them back to the home page. Otherwise, continue with the login. */
        //console.log("Login Page Storage: ", localStorage.getItem('user'));

        if (getAuthStatus()) {
            redirectToHomePage();
        }

    }, [])

    return (
        <div className='sq-app-container'>
            <div id='sq-app'>
                <PageTitle />
                <div id="select-game-div" className="sq-header">
                    <form className='sq-login-form' onSubmit={handleSubmit(onSubmit)}>
                        <div id='sq-login-errorbox' className='sq-login-inactive'>{errorMessage}</div>
                        <label className='sq-login-label'>Email Address</label>
                        <input className='sq-login-input'{...register("email", { required: true })} />
                        {errors.email && <p className='sq-login-error'>This field is required.</p>}
                        <label className='sq-login-label'>Password</label>
                        <input type={"password"} className='sq-login-input'{...register("password", { required: true })} />
                        {errors.password && <p className='sq-login-error'>This field is required.</p>}
                        <input className='sq-login-button' type="submit" value="Login" disabled={authing} />
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;