import React from "react";
import siegeLogo from "../images/siege-icon.png"
import valorantLogo from "../images/val-icon.png"
import leagueLogo from "../images/lol-icon.png"
import pubgLogo from "../images/pubg-icon.png"
import rocketLeagueLogo from "../images/rocket-league-icon.png"

interface Props {
    game: string // siege
    name: string // Rainbow Six: Siege
    logo: string | undefined
    handleClick: React.MouseEventHandler<HTMLInputElement>
}

function Button(props: Props) {
    let logo;
    switch(props.game){
        case "siege":
            logo = siegeLogo;
            break;
        case "valorant":
            logo = valorantLogo;
            break;
        case "lol":
            logo = leagueLogo;
            break;
        case "pubg":
            logo = pubgLogo;
            break;
        case "rocketleague":
            logo = rocketLeagueLogo;
            break;
        default:
            break;
    }

    return <input value={props.game} id={props.game} className="sq-image-button" type="image" alt={props.name} src={logo} title={props.name} onClick={props.handleClick} />
}

export default Button;