import CustomNavbar from "../../components/nav";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import valLogo from "../../images/val-icon.png";
import React from "react";
import activateMessageBox from "../../wrappers/activateMessageBox";
import { useNavigate } from "react-router-dom";
import getUserPermissions from "../../wrappers/getUserPermissions";

function Valorant() {
    const {
        register,
        handleSubmit
    } = useForm({
        defaultValues: {
            information: ""
        }
    });

    const [message, setMessage] = useState("");

    //const baseURL = "http://localhost:8080";
    const baseURL = "https://soniqs-databank.uc.r.appspot.com";

    const triggerForm = (color: string, message: string) => {
        activateMessageBox(color);
        setMessage(message);
    }

    const submitData = async (values: string) => {
        /* Trim any white space or character breaks. */
        values.trim();

        /* If the last value of the string is a comma, trim the string by one. */
        const lastChar = values.charAt(values.length - 1);
        if (lastChar === ","){
            values = values.substring(0, values.length - 1);
        }

        await fetch(`${baseURL}/valorant/AddItemsToQueue/${values}`).then(response => response.json()).then(item => {
           triggerForm('black', `Current Queue: ${item.toString()}`)
        }).catch((error) => {
            triggerForm('red', `Error retrieving match ID: ${error.message}`)
        })
    }

    const onSubmit = (data: any) => {
        const rawInput = data.information;
        
        if (!rawInput || rawInput === ""){
            triggerForm('red', `Please enter at least one ID to import.`);
        }else{
            /* Make an API call to the database that gets the scraped-data collection > valorant document > queue field, then appends the list of ID's to the queue. Return value will be a message with the current queue list. */
            //submitData((data.information).split(","));
            setMessage("");
            submitData(data.information)
        }
    }

    const navigate = useNavigate();
    useEffect(() => {
        if(getUserPermissions('valorant') === false){
            navigate('/Unauthorized');
        }
    }, []);

    return (
        <>
            <CustomNavbar />
            <div className='sq-app-container'>
                <div id='sq-app'>
                    <div id="select-game-div" className="sq-header">
                        <div>
                            <img id='sq-import-logo' src={valLogo} className="sq-logo" alt="logo" />
                        </div>
                        
                        <div className='sq-import-text'>Enter VLR ID's in the text box below. To add multiple matches, separate ID's with a comma (ex: 1234,5678).</div>

                        <form className='sq-import-form' onSubmit={handleSubmit(onSubmit)}>
                            <input className='sq-import-input valorant' placeholder="ID0001,ID0002" {...register("information", { required: false})}></input>
                            <input id='sq-import-button' className='sq-import-button sq-button-active' type="submit" value="Import"/>
                            <div id='sq-import-message' className='sq-login-inactive'>{message}</div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Valorant;