import { createContext } from "react";

const initialState = {
    selectedGame: ""
};

export type DataState = typeof initialState;

const context = createContext<typeof initialState>(initialState);

export default context;