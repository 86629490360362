import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../images/soniqs-logo.png';
import Dropdown from './dropdown';
import React from 'react';
import AdminDropdown from './admin';

function Nav() {
    const navigate = useNavigate();
    const location = useLocation();
    const [canImport, setImport] = useState(false);
    const [canUpload, setUpload] = useState(false);
    const [canDownload, setDownload] = useState(false);
    const [isAdmin, setAdmin] = useState(false);

    const navigateToHomePage = () => {
        if (location.pathname === "/stats"){
            navigate(0);
        }else{
            navigate("/stats");
        }
    }

    const navigateToUploadPage = () => {
        if (location.pathname === "/upload"){
            navigate(0);
        }else{
            navigate("/upload");
        }
    }

    const navigateToDownloadPage = () => {
        if (location.pathname === "/download"){
            navigate(0);
        }else{
            navigate("/download");
        }
    }

    const navigateToExportPage = () => {
        if (location.pathname === "/export"){
            navigate(0);
        }else{
            navigate("/export");
        }
    }

    const navigateToAdminPage = () => {
        if (location.pathname === "/admin"){
            navigate(0);
        }else{
            navigate("/admin");
        }
    }

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user')!);
        
        setUpload(user.canUpload);
        setDownload(user.canDownload);
        setImport(user.canImport);
        setAdmin(user.isAdmin);
    }, [])

    
    return (
        <div className='sq-nav'>
            <div className='sq-nav-container'>
                {/* Left side of the nav bar + Spacer */}
                <input  id="nav-logo" className="sq-nav-button" type="image" alt="logo" src={logo} />
                <span id='nav-text'>Soniqs API Dashboard</span>
                <div id='nav-spacer'/>

                {/* Middle nav */}
                <button className='sq-nav-button' id='sq-nav-home' onClick={navigateToHomePage}>Stats</button>
                { canUpload ? <button className='sq-nav-button' id='sq-nav-upload' onClick={navigateToUploadPage}>Upload</button> : null }
                { canDownload ? <button className='sq-nav-button' id='sq-nav-download' onClick={navigateToDownloadPage}>Download</button> : null }
                <button className='sq-nav-button' id='sq-nav-export' onClick={navigateToExportPage}>Export</button>
                { canImport ? <Dropdown /> : null }
                { isAdmin ? <AdminDropdown /> : null }
            </div>
        </div>
    )
}

export default Nav;