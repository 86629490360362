import "./App.css"
import { initializeApp } from 'firebase/app';
import config from './config/config';
import MainRoutes from "./components/routes";
import React from "react";

function App() {
	initializeApp(config.firebaseConfig);

	return (
		<MainRoutes />
	)
}

export default App;
