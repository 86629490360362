import CustomNavbar from "../components/nav";
import { useContext, useEffect, useState } from 'react';
import React from "react";
import options from "../options/dropdownItems.json"
import DropdownItems from "../interfaces/dropdownItems";
import DownloadFile from "../interfaces/downloadFile";
import downloadContext, { DataState } from "../interfaces/downloadStore";
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import getUserPermissions from "../wrappers/getUserPermissions";
import { useNavigate } from "react-router-dom";
import setActiveNav from "../wrappers/setActiveNav";

function Consumer() {
    const data = useContext(downloadContext);
    const headerWidth = 300;
    const headerAlign = 'center';
    const headerType = 'string';
    const tableColumns: GridColDef[] = [{
        field: 'game',
        headerName: 'Game',
        type: headerType,
        headerAlign: headerAlign,
        align: headerAlign,
        minWidth: 150,
        flex: 0.3
    },{
        field: 'fileName',
        headerName: 'File Name',
        type: headerType,
        headerAlign: headerAlign,
        renderCell: (params: GridRenderCellParams<DownloadFile>) => (
            <a href={params.row.downloadURL}>{params.row.fileName}</a>
        ),
        align: headerAlign,
        minWidth: 150,
        flex: 2
    },{
        field: 'dateUpdated',
        headerName: 'Date Updated',
        type: headerType,
        headerAlign: headerAlign,
        align: headerAlign,
        minWidth: 150,
        flex: 2
    }];

    useEffect(() => {
        setActiveNav('download');
    })

    return (
        <div id='sq-download-div'>
            <p className='sq-import-text' id='sq-download-header'>Select a file to download</p>
            <DataGrid hideFooter={true} hideFooterSelectedRowCount={true} autoHeight={true} rows={data.files} columns={tableColumns} />
        </div>
    )
}

function Provider() {
    const [data, setData] = useState<DataState>({
        files: [],
        readyToRender: false,
    });
    

    const baseURL = "https://soniqs-databank.uc.r.appspot.com";
    //const baseURL = "http://localhost:8080"

    const navigate = useNavigate();
    useEffect(() => {
       
        const fetchData = async () => {
            var data: any = [];
            /* For each dropdown option (IE: game), retrieve the files we have stored so we can display them. */
            const promises = options.map(async (option: DropdownItems) => {
                const url = `${baseURL}/getStorageFiles/${option.value}`;
                await fetch(url).then(response => response.json()).then(item => {
                    item.map((itemData: any) => {
                        data.push(itemData);
                    });
                });
            })

            /* This just means wait until all the promises (aka API calls) above have finished before moving on. */
            await Promise.all(promises);

            /* Once we have the data, sort it by game because it often comes back in different oders from the API, so we want to make things consistent. */
            const sortedItems = data.sort(
                function (a: DownloadFile, b: DownloadFile) {
                    const valueA = a.game;
                    const valueB = b.game;
    
                    if (valueA < valueB) { return -1 }
                    if (valueA > valueB) { return 1 }
                    return 0;
                }
            )

            /* Set the state data with our new sorted items array and also indicate we are ready to render the page. */
            setData({
                files: sortedItems,
                readyToRender: true
            })
        }

        fetchData();
    }, []);

    return (
        <>
            <CustomNavbar />
            <div className='sq-app-container'>
                <div id='sq-app'>
                    {(data.readyToRender === true ?
                        <>
                            <downloadContext.Provider value={data}>
                                <Consumer />
                            </downloadContext.Provider>
                        </> : <div className='sq-loading'><p className='sq-loading-text'>Loading files...</p></div>)}
                </div>
            </div>
        </>
    )
}

export default Provider;