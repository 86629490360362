const config = {
    firebaseConfig: {
        apiKey: "AIzaSyAsqfAQ6zZ8XPpiAuwc9TkHRsYtZeiyiiE",
        authDomain: "soniqs-databank.firebaseapp.com",
        databaseURL: "https://soniqs-databank-default-rtdb.firebaseio.com",
        projectId: "soniqs-databank",
        storageBucket: "soniqs-databank.appspot.com",
        messagingSenderId: "88070061454",
        appId: "1:88070061454:web:1a53992b8080a9d5f638df",
        measurementId: "G-Q5WE0ZZ8WY"
    }
};

export default config;