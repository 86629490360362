import React, { useEffect } from "react"
import CustomNavbar from "../../components/nav";
import getUserPermissions from "../../wrappers/getUserPermissions";
import setActiveNav from "../../wrappers/setActiveNav";
import { useNavigate } from "react-router-dom";
import { Checkbox, FormControl, FormControlLabel, FormLabel, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import miscItems from "../../options/miscInformation.json";
import dropdownItems from "../../options/dropdownItems.json";
import personalInformation from "../../options/personalInformation.json";
import PersonalItem from "../../interfaces/personalInformation";
import DropdownItem from '../../interfaces/dropdownItems';

function Admin() {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: {
            canImport: false,
            canExport: false,
            canUpload: false,
            canDownload: false,
            isAdmin: false,
            firstName: null,
            lastName: null,
            email: null,
            siege: null,
            rocketleague: null,
            valorant: null
        }
    });

    const navigate = useNavigate();
    useEffect(() => {
        if (getUserPermissions('admin') === false) {
            navigate('/Unauthorized');
        }

        setActiveNav('admin');
    }, []);

    const onSubmit = (data: any) => {
        console.log(data);
        let queryString = "";
        queryString = `firstName=${data.firstName}&lastName=${data.lastName}&email=${data.email}`;
        if (data.siege) queryString += `&siege=true`
        if (data.rocketleague) queryString += `&rocketleague=true`
        if (data.valorant) queryString += `&valorant=true`
        if (data.canExport) queryString += `&canExport=true`
        if (data.canImport) queryString += `&canImport=true`
        if (data.canDownload) queryString += `&canDownload=true`
        if (data.canUpload) queryString += `&canUpload=true`
        if (data.isAdmin) queryString += `&isAdmin=true`
    }

    return (
        <>
            <CustomNavbar />
            <div className='sq-app-container'>
                <div id='sq-app'>
                    <form className='sq-admin-adduser' onSubmit={handleSubmit(onSubmit)}>
                        <div id='sq-admin-adduser'>
                            <div className='sq-import-text exportHeader'>Create New User</div>
                            <table className='addUserTable centered'>
                                <tbody>
                                    <tr>
                                        {/* Personal Information */}
                                        <td>
                                            {(personalInformation.length > 0 ? personalInformation.map((item: PersonalItem | any) => (
                                                <FormControl key={item.id} sx={{ m: 1, minWidth: 250, display: "block" }}>
                                                    <TextField
                                                        id={item.id}
                                                        placeholder={item.placeholder}
                                                        label={item.fieldName}
                                                        required={item.required}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        margin="dense"
                                                        {...register(item.id, { required: true })}
                                                    />
                                                </FormControl>
                                            )) : null)}
                                        </td>

                                        {/* Associated Games */}
                                        <td>
                                            <FormLabel sx={{ m: 0 }} component="legend">Associated Games</FormLabel>
                                            <FormControl sx={{ m: 0 }}>
                                                {(dropdownItems.length > 0 ? dropdownItems.map((item: DropdownItem | any) => (
                                                    <FormControlLabel key={item.value} control={<Checkbox key={item.value} {...register(item.value, { required: false })} sx={{ m: 0, p: .5 }} />} label={item.title} />
                                                )) : null)}
                                            </FormControl>
                                        </td>

                                        {/* User Permissions */}
                                        <td>
                                            <FormLabel sx={{ m: 0 }} component="legend">User Permissions</FormLabel>
                                            <FormControl sx={{ m: 0 }}>
                                                {(miscItems.length > 0 ? miscItems.map((item: any) => (
                                                    <FormControlLabel key={item.id} control={<Checkbox {...register(item.id, { required: false })} sx={{ m: 0, p: .5 }} />} label={item.name} />
                                                )) : null)}
                                            </FormControl>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <hr />
                            <input id='sq-upload-button' className='sq-import-button export' type="submit" value="Submit" />
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}

export default Admin