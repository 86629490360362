import React, { useState, useContext, useEffect } from "react"
import dashboardContext, { DataState } from "../interfaces/store"
import CustomButton from "./button"
import options from "../options/options.json"
import { DataGrid } from '@mui/x-data-grid';
import PageTitle from "./title";
import Endpoint from "../interfaces/endpoint";
import Game from "../interfaces/game";
import APIData from "../interfaces/apidata";
import CustomNavbar from "./nav";
import setActiveNav from "../wrappers/setActiveNav";

const baseURL = "https://soniqs-databank.uc.r.appspot.com";
//const baseURL = "http://localhost:8080"

/* Does a fetch to retrieve API Data for a certain game and endpoint. Returns null if the fetch call errors, otherwise returns an object with the data. */
async function retrieveAPIData(option: Game, dataset: Endpoint) {
    const url = `${baseURL}/${dataset.endpoint}/${option.key}`;

    let returnData: any;
    document.body.style.cursor = 'wait';

    await fetch(url).then(response => response.json()).then(item => {
        if (item.hasOwnProperty('Error')){
            returnData = null;
        }else{
            if (item[0].hasOwnProperty('id') === false) {
                item.forEach((item: any, index: number) => item.id = index + 1)
            }
    
            returnData = {
                game: option.key,
                key: `${option.key} ${dataset.displayValue}`,
                displayValue: dataset.displayValue,
                value: item
            }
        }
    }).catch((error) => {
        console.log(error);
    });

    document.body.style.cursor = 'default';

    return returnData;
}

function Consumer() {
    const data = useContext(dashboardContext);
    const [gameName, setGameName] = useState("");
    const [dataset, setDataset] = useState("");
    const [tableData, setTableData] = useState([]);
    const [tableCols, setTableCols] = useState([]);
    const [dataSetOptions, setDataSetOptions] = useState([]);
    const [errorMessage, setErrorMessage] = useState<String | null>(null);

    /* Whenever a data set is selected, we do two things:
     * 1. Retrieve the already-retrieved API Data for that data set. This becomes our Table Data.
     * 2. Iterate over the API Data keys for the first index of data and extract the keys. These become our Table Headers.
     * 3. The data in steps 1-2 are then used to render our data table. */
    const handleClick = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedDataSet = event.currentTarget.value;
        if (selectedDataSet === "Select a dataset") {
            return;
        }

        const game: any = (options.find((item: Game) => item.key === data.selectedGame));
        const endpoint = (game.endpoints.find((item: Endpoint) => item.endpoint === selectedDataSet));

        /* Retrieve the API data for this game and dataset */
        const apiData: any = await retrieveAPIData(game, endpoint);

        if (apiData === null){
            setErrorMessage("Could not find any data for the selected dataset. Please select a different dataset.");
        }else{
            setErrorMessage(null);
        }

        setGameName(game.title);
        setDataset(apiData.displayValue);

        /* Set Table Data will populate the rows inside of my data table with all of the API data. */
        setTableData(apiData.value);

        /* Iterate over the first row of data and grab all of the keys. These will form my data table header columns. */
        var columns: any = [];
        Object.keys(apiData.value[0]).map((key: any, index: number) => {
            return columns.push({
                field: key,
                headerName: key,
                width: 150,
                headerAlign: 'center',
                align: 'center'
            });
        });
        setTableCols(columns);
    }

    /* Every time a game is selected/changed, we run this method. This method simply populates the dropdown that gives you the data sets to choose from. */
    useEffect(() => {
        /* If no game has been selected (IE we are rendering the page for the first time), return. */
        if (!data.selectedGame) {
            return;
        }

        setActiveNav('home');

        /* When the game changes, clear any error messages. */
        setErrorMessage(null);

        /* If we have data in our Table and this is triggering, we must be changing our game. If so, empty the data table. */
        if (tableData) {
            setTableData([])
        }

        var items: any = [];
        var endpoints: any = options.find((item: Game) => item.id === data.selectedGame)?.endpoints;

        /* If no items exist for this particular game, return. */
        if (!endpoints || endpoints.length === 0) {
            return;
        }

        /* This will populate the items in the Dataset dropdown. The items correspond to the game that's been selected. */
        endpoints.forEach((endpoint: any) => {
            items.push(endpoint);
        });

        setDataSetOptions(items.sort());
    }, [data.selectedGame]) // This state should trigger whenever a game is changed OR when the page is ready to render.

    /* This returns the div that houses the data table where our stats are displayed. It uses the context variables that are set and consumes them. */
    return (
        <div id='sq-data'>
            <div>
                <select onChange={handleClick} id='sq-select-dataset' className='sq-inactive'>
                    <option key="default" value="default">Select a dataset</option>
                    {(dataSetOptions.length > 0 ? dataSetOptions.map((option: any) => (
                        <option key={option.endpoint} value={option.endpoint}>{option.displayValue}</option>
                    )) : null)}
                </select>
            </div>
            {(errorMessage != null ? <p>{errorMessage}</p> : null)}
            {(tableData.length > 0 ? <label className="sq-label">{gameName} | {dataset}</label> : null)}
            <div className='sq-stats-div'>
                {(tableData.length > 0 ? <DataGrid rows={tableData} columns={tableCols} /> : null)}
            </div>
        </div>
    )
}

function Provider() {
    const [data, setData] = useState<DataState>({
        selectedGame: ""
    })

    /* When we click the Game buttons, all that should happen is the selected game value should populate. */
    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
        const activeButtonClassName = "sq-active-button"
        const activeButtons = document.querySelectorAll(`.${activeButtonClassName}`);
        activeButtons.forEach((button: Element) => {
            button.classList.remove(activeButtonClassName);
        });

        var dataSetSelector = document.getElementById("sq-select-dataset");
        if (dataSetSelector) {
            dataSetSelector.classList.remove("sq-inactive");
        }

        /* Set this as the active button */
        event.currentTarget.classList.add(activeButtonClassName);

        /* Update the selected game */
        setData({
            selectedGame: event.currentTarget.value
        })
    }

    useEffect(() => {
        /* Set the class on the top nav for the Home button. */
        setActiveNav('home');
    }, [])

    return (
        <>
            <CustomNavbar />
            <div className="sq-app-container">
                <dashboardContext.Provider value={data}>
                    <div id='sq-app'>
                        {/* <Nav /> */}
                        <PageTitle />
                        <div id="select-game-div" className="sq-header">
                            <label className="sq-label">Select a game</label>
                            {options.map((option: Game) => {
                                return <CustomButton key={option.key} game={option.key} name={option.title} logo={option.logo} handleClick={handleClick} />;
                            })}
                        </div>
                    </div>
                    <Consumer />
                </dashboardContext.Provider>
            </div>
        </>
    )
}

export default Provider;