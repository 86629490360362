import  { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import React, { FC } from "react";
import getAuthStatus from "../wrappers/getAuthStatus";

const ProtectedRoutes: FC<{ children: React.ReactElement }> = ({ children }) => {
    const userIsLoggedIn = getAuthStatus();
    const location = useLocation();
    
    if (!userIsLoggedIn){
        console.log("User not determined to be logged in, returning to login page...");
        return <Navigate to='/login' />
    }

    /* If we're coming here from the home page URL, redirect to the Stats page instead of '/' because we need to set permissions properly. */
    if (location.pathname === '/'){
        return <Navigate to='/stats' />
    }

    return children;
}

export default ProtectedRoutes;