import CustomNavbar from "../components/nav";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import React from "react";
import { Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from "react-router-dom";
import getUserPermissions from "../wrappers/getUserPermissions";
import listItems from '../options/listItems.json';
import ListItem from '../interfaces/listItem';
import setActiveNav from "../wrappers/setActiveNav";
import activateMessageBox from "../wrappers/activateMessageBox";

//const baseURL = "http://localhost:8080";
const baseURL = "https://soniqs-databank.uc.r.appspot.com";

function ExportMatches() {
    const {
        handleSubmit
    } = useForm({
    });

    const navigate = useNavigate();

    const [state, setState] = useState({
        game: "",
        games: { type: "", values: [] as string[] },
        startDate: `2022-01-01`,
        endDate: `${new Date().getFullYear()}-12-31`,
        season: '',
        stage: '',
        playday: '',
        event: '',
        events: { type: "", values: [] as string[] },
        eventID: '',
        eventName: '',
        tier: '',
        tiers: { type: "", values: [] as string[] },
        type: '',
        types: { type: "", values: [] as string[] },
        region: '',
        regions: { type: "", values: [] as string[] },
        format: '',
        formats: { type: "", values: [] as string[] },
        team: '',
        blue: '',
        orange: '',
        opponent: '',
        map: '',
        maps: { type: "", values: [] as string[] }
    });

    const {game, games, startDate, endDate, season, stage, playday, event, events, eventID, eventName, tier, tiers, type, types, region, regions, format, formats, team, blue, orange, opponent, map, maps} = state;
    const [showAdditionalFilters, setShowAdditionalFilters] = useState<Boolean>(false);
    const [message, setMessage] = useState<any>(null);
    const [color, setColor] = useState<any>(null);
    const [gameHelpText, setGameHelpText] = useState<string | null>(null);
    const [seasonHelpText, setSeasonHelpText] = useState<string | null>(null);
    const [startDateHelpText, setStartDateHelpText] = useState<string | null>(null);

    const handleChange = (event: any) => {
        setMessage(null);
        setColor(null);
        
        switch (event.target.name) {
            case "game":
                const selectedGame = event.target.value;

                /* Set our optional filters depending on the game we select */
                switch (selectedGame) {
                    case "Siege":
                        setState({
                            ...state,
                            game: selectedGame,
                            formats: listItems.find((item: ListItem) => item.type === 'siegeFormats')!,
                            types: listItems.find((item: ListItem) => item.type === 'siegeTypes')!,
                            events: listItems.find((item: ListItem) => item.type === 'siegeEvents')!,
                            tiers: listItems.find((item: ListItem) => item.type === 'siegeTiers')!,
                            regions: listItems.find((item: ListItem) => item.type === 'siegeRegions')!,
                            maps: listItems.find((item: ListItem) => item.type === 'siegeMaps')!
                        });
                        break;
                    case "Rocket League":
                        setState({
                            ...state,
                            game: selectedGame,
                            formats: listItems.find((item: ListItem) => item.type === 'siegeFormats')!,
                            types: listItems.find((item: ListItem) => item.type === 'siegeTypes')!,
                            regions: listItems.find((item: ListItem) => item.type === 'rlRegions')!,
                            maps: listItems.find((item: ListItem) => item.type === 'rlMaps')!,
                            tiers: listItems.find((item: ListItem) => item.type === 'rlTiers')!
                        });
                        break;
                    default:
                        break;
                }
                break;
            default:
                setState({
                    ...state,
                    [event.target.name]: event.target.value
                });
                break;
        }
    }

    const checkForErrors = () => {
        var hasErrors = false;
        const errorText = "This is a required field."

        if (!game) {
            setGameHelpText(errorText);
            hasErrors = true;
        } else if (game && gameHelpText) {
            setGameHelpText(null);
        }

        if (startDate && endDate && (startDate > endDate)) {
            setStartDateHelpText("End Date must be greater than the Start Date.");
            hasErrors = true;
        } else {
            setStartDateHelpText(null);
        }

        return hasErrors;
    }

    const saveDataToFile = (gameName: string, collection: string, item: any) => {
        const jsonString = `data:text/json;chatset=utf-8, ${encodeURIComponent(JSON.stringify(item))}`;
        const link = document.createElement('a');
        link.href = jsonString;
        link.download = `${gameName}-${collection}.json`;
        link.click();
    }

    const triggerForm = (color: any, message: any) => {
        activateMessageBox(color);
        setMessage(message);
    }

    /* Control what happens when the form is submitted. */
    const onSubmit = async (data: any) => {
        /* Validate the required filters */
        if (checkForErrors() === true) return;

        triggerForm('black', "Getting data to export. This process may take a while...");

        /* Add the required filters to our API header */
        let queryString = `startDate=${startDate}&endDate=${endDate}`;

        /* Include the optional filters if we need to */
        if (showAdditionalFilters) {
            switch (game) {
                case "Siege":
                    queryString += `&season=${season}`;
                    if (playday) queryString += `&playday=${playday}`;
                    if (event) queryString += `&event=${event}`;
                    break;
                case "Rocket League":
                    if (stage) queryString += `&stage=${stage}`;
                    if (eventID) queryString += `&eventid=${eventID}`;
                    if (eventName) queryString += `&eventname=${event}`;
                    if (blue) queryString += `&blue=${blue}`;
                    if (orange) queryString += `&orange=${orange}`;
                    break;
                default:
                    break;
            }

            /* These additional filters should exist for every game */
            if (tier) queryString += `&tier=${tier}`;
            if (type) queryString += `&type=${type}`;
            if (region) queryString += `&region=${region}`;
            if (format) queryString += `&format=${format}`;
            if (map) queryString += `&map=${map}`;
            if (team) queryString += `&team=${team}`;
            if (opponent) queryString += `&opponent=${opponent}`;
        }

        let collections: any = [];
        let gameName = "";

        switch (game) {
            case "Siege":
                collections = (listItems.find((item: ListItem) => item.type === 'siegeCollections')!);
                gameName = "siege";
                break;
            case "Valorant":
                gameName = "valorant";
                break;
            case "Rocket League":
                collections = (listItems.find((item: ListItem) => item.type === 'rlCollections')!);
                gameName = "rocketleague";
                break;
            default:
                break;
        }

        if (!collections) {
            throw new Error("No collections defined.");
        }

        let errorMessage = null;
        let hasErrors = false;

        /* Submit the API Request and get the data back from all 4 collections */
        for (const collection of collections.values) {
            console.log(`${baseURL}/exportData/${gameName}/${collection}/?${queryString}`);
            await fetch(`${baseURL}/exportData/${gameName}/${collection}/?${queryString}`).then(response => response.json()).then(async item => {
                /* If we can't find any documents, throw an error message. */

                if (item.status === 'error'){
                    errorMessage = item.message;
                    hasErrors = true;
                }
                
                if (!hasErrors) saveDataToFile(gameName, collection, item.results);
            });
        }

        if (hasErrors){
            triggerForm('red', errorMessage);
        }else{
            triggerForm('black', "Export process finished.");
        }
    }

    /* Toggle the Additional Filters section with the checkbox. */
    const toggleAdditionalFilters = (event: any) => {
        setShowAdditionalFilters(event.target.checked);
    }

    const clearFilters = (event: any) => {
        /* Clear the optional filter values. */
        setState({
            ...state,
            event: '',
            tier: '',
            type: '',
            region: '',
            format: '',
            map: '',
            playday: '',
            team: '',
            opponent: '',
            eventID: '',
            eventName: '',
            blue: '',
            orange: '',
            stage: ''
        });

        setMessage(null);
    }

    useEffect(() => {
        if (getUserPermissions('export') === false) {
            navigate('/Unauthorized');
        }

        /* Add a highlight under the Export button on the top nav */
        setActiveNav('export');

        /* Required Filters */
        setState({
            ...state,
            games: listItems.find((item: ListItem) => item.type === 'games')!
        });
    }, []);

    return (
        <>
            <CustomNavbar />
            <div className='sq-app-container'>
                <div id='sq-app'>
                    <form className='sq-import-form' onSubmit={handleSubmit(onSubmit)}>
                        <div id='sq-export'>
                            <div className='sq-import-text exportHeader'>Required Filters</div>
                            <div className='sq-export-filters'>
                                <FormControl sx={{ m: 1, minWidth: 225 }}>
                                    <InputLabel id='sq-tier-select'>Game</InputLabel>
                                    <Select labelId='sq-tier-select-label' id='sq-tier-select-helper' name='game' value={game} label='Game' onChange={handleChange}>
                                        {games.values.map((value: string) => (<MenuItem key={value} value={value}>{value}</MenuItem>))}
                                    </Select>
                                    {gameHelpText ? <FormHelperText><span className="sq-helpText">{gameHelpText}</span></FormHelperText> : null}
                                </FormControl>
                                <FormControl sx={{ m: 1, minWidth: 225 }}>
                                    <TextField
                                        id="sq-date-start"
                                        label="Start Date"
                                        type="date"
                                        name="startDate"
                                        defaultValue={startDate}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    {startDateHelpText ? <FormHelperText><span className="sq-helpText">{startDateHelpText}</span></FormHelperText> : null}
                                </FormControl>
                                <FormControl sx={{ m: 1, minWidth: 225 }}>
                                    <TextField
                                        id="sq-date-end"
                                        label="End Date"
                                        type="date"
                                        name="endDate"
                                        defaultValue={endDate}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                            </div>

                            {/* Optional Filters */}
                            {showAdditionalFilters ? <>
                                <div className='sq-import-text exportHeader additionalFilter'>Optional Filters</div>
                                <div className='sq-export-filters additionalFilter'>
                                    {/* SEASON */}
                                    {game === 'Rocket League' ? null :
                                    <FormControl sx={{ m: 1, minWidth: 225 }}>
                                        <TextField
                                            id="sq-seasons"
                                            label="Season"
                                            placeholder='ex: 2200'
                                            defaultValue={season}
                                            name="season"
                                            onChange={handleChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {seasonHelpText ? <FormHelperText><span className="sq-helpText">{seasonHelpText}</span></FormHelperText> : null}
                                    </FormControl>}
                                    {/* STAGE */}
                                    {game === 'Rocket League' ? <FormControl sx={{ m: 1, minWidth: 225 }}>
                                    <TextField
                                        id="sq-stage"
                                        label="Stage"
                                        placeholder="ex: 1"
                                        name='stage'
                                        defaultValue={stage}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    {seasonHelpText ? <FormHelperText><span className="sq-helpText">{seasonHelpText}</span></FormHelperText> : null}
                                </FormControl> : null }
                                    {/* EVENT */}
                                    {game === 'Rocket League' ? <span><FormControl sx={{ m: 1, minWidth: 225 }}>
                                        <TextField
                                            id="sq-event-id"
                                            label="Event ID"
                                            placeholder='6043152fa09e7fba40d2ae62'
                                            value={eventID}
                                            name='eventID'
                                            onChange={handleChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl><FormControl sx={{ m: 1, minWidth: 225 }}>
                                            <TextField
                                                id="sq-event-name"
                                                label="Event Name"
                                                placeholder='RLCS Season 2 World Championship'
                                                value={eventName}
                                                name='eventName'
                                                onChange={handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                    </span> : <FormControl sx={{ m: 1, minWidth: 225 }}>
                                        <InputLabel id='sq-tier-select'>Event</InputLabel>
                                        <Select labelId='sq-tier-select-label' id='sq-tier-select-helper' name='event' value={event} label='Event' onChange={handleChange}>
                                            {events.values.map((value: string) => (<MenuItem key={value} value={value}>{value}</MenuItem>))}
                                        </Select>
                                    </FormControl>}

                                    {/* TIER */}
                                    <FormControl sx={{ m: 1, minWidth: 225 }}>
                                        <InputLabel id='sq-tier-select'>Tier</InputLabel>
                                        <Select labelId='sq-tier-select-label' id='sq-tier-select-helper' name='tier' value={tier} label='Tier' onChange={handleChange}>
                                            {tiers.values.map((value: string) => (<MenuItem key={value} value={value}>{value}</MenuItem>))}
                                        </Select>
                                    </FormControl>

                                    {/* TYPE */}
                                    {game !== 'Rocket League' ? <FormControl sx={{ m: 1, minWidth: 225 }}>
                                        <InputLabel id='sq-tier-select'>Type</InputLabel>
                                        <Select labelId='sq-tier-select-label' id='sq-tier-select-helper' name='type' value={type} label='Type' onChange={handleChange}>
                                            {types.values.map((value: string) => (<MenuItem key={value} value={value}>{value}</MenuItem>))}
                                        </Select>
                                    </FormControl> : null}

                                    {/* REGION */}
                                    <FormControl sx={{ m: 1, minWidth: 225 }}>
                                        <InputLabel id='sq-tier-select'>Region</InputLabel>
                                        <Select labelId='sq-tier-select-label' id='sq-tier-select-helper' name='region' value={region} label='Region' onChange={handleChange}>
                                            {regions.values.map((value: string) => (<MenuItem key={value} value={value}>{value}</MenuItem>))}
                                        </Select>
                                    </FormControl>

                                    {/* FORMAT */}
                                    <FormControl sx={{ m: 1, minWidth: 225 }}>
                                        <InputLabel id='sq-tier-select'>Format</InputLabel>
                                        <Select labelId='sq-tier-select-label' id='sq-tier-select-helper' name='format' value={format} label='Format' onChange={handleChange}>
                                            {formats.values.map((value: string) => (<MenuItem key={value} value={value}>{value}</MenuItem>))}
                                        </Select>
                                    </FormControl>

                                    {/* MAP */}
                                    <FormControl sx={{ m: 1, minWidth: 225 }}>
                                        <InputLabel id='sq-tier-select'>Map</InputLabel>
                                        <Select labelId='sq-tier-select-label' id='sq-tier-select-helper' name='map' value={map} label='Map' onChange={handleChange}>
                                            {maps.values.map((value: string) => (<MenuItem key={value} value={value}>{value}</MenuItem>))}
                                        </Select>
                                    </FormControl>

                                    {/* PLAY DAY */}
                                    {game !== 'Rocket League' ? <FormControl sx={{ m: 1, minWidth: 225 }}>
                                        <TextField
                                            id="sq-playday"
                                            label="Playday"
                                            placeholder='1-xxxx'
                                            value={playday}
                                            name='playday'
                                            onChange={handleChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl> : null}

                                    {/* TEAM */}
                                    {game === 'Rocket League' ? <FormControl sx={{ m: 1, minWidth: 225 }}>
                                        <TextField
                                            id="sq-blue"
                                            label="Blue Team"
                                            value={blue}
                                            placeholder='SQ'
                                            name='blue'
                                            onChange={handleChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl> : <FormControl sx={{ m: 1, minWidth: 225 }}>
                                        <TextField
                                            id="sq-team"
                                            label="Team"
                                            value={team}
                                            name='team'
                                            placeholder='SQ'
                                            onChange={handleChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>}

                                    {/* OPPONENT */}
                                    {game === 'Rocket League' ? <FormControl sx={{ m: 1, minWidth: 225 }}>
                                        <TextField
                                            id="sq-orange"
                                            label="Orange Team"
                                            value={orange}
                                            name='orange'
                                            placeholder='DZ'
                                            onChange={handleChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl> : <FormControl sx={{ m: 1, minWidth: 225 }}>
                                        <TextField
                                            id="sq-opponent"
                                            label="Opponent"
                                            placeholder='DZ'
                                            name='opponent'
                                            value={opponent}
                                            onChange={handleChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>}
                                </div>
                            </> : null}

                            <hr />
                            <div className='sq-export-filters export-submit'>
                                <input id='sq-upload-button' className='sq-import-button export' type="submit" value="Export" />
                                <FormControl sx={{ m: 1, minWidth: 225 }}>
                                    <FormControlLabel control={<Checkbox onChange={toggleAdditionalFilters} />} label="Show Additional Filters" />
                                </FormControl>
                                <FormControl sx={{ m: 1, minWidth: 225 }}>
                                    <FormControlLabel control={<Checkbox checked={false} onChange={clearFilters} />} label="Reset Additional Filters" />
                                </FormControl>
                            </div>

                        </div>
                        {message != null ? <div id='sq-import-message' className='sq-upload-message'><p>{message}</p></div> : null}
                    </form>
                </div>
            </div>
        </>
    )
}

export default ExportMatches;