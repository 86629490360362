import React from "react";
import CustomNavbar from "../components/nav";

function Unauthorized(){
    return(
        <>
            <CustomNavbar />
            <div className='sq-app-container'>
                <div id='sq-app'>You do not have access to the requested page. Please contact your administrator for more information.</div>
            </div>
        </>
    )
}

export default Unauthorized