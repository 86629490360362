/* Only a certain subset of users should be able to import data into our database. As such, if a user can import, the Import tab will show in the navbar. Otherwise, that component will not be rendered. */
export default function getUserPermissions(page: string) {
    const user = JSON.parse(localStorage.getItem('user')!);

    switch (page) {
        case "upload":
            return user.canUpload;
        case "download":
            return user.canDownload;
        case "export":
            return user.canExport;
        case "admin":
            return user.isAdmin;
        case "siege":
        case "valorant":
        case "rocketleague":
            /* For the import games, we want to make sure the user can import AND also has permissions for that specific game. */
            if (user.canImport === false) return false;
            return user.games.includes(page);
        default:
            break;
    }
}