export default async function setActiveNav(page: string){
    const navButton = document.getElementById(`sq-nav-${page}`);
    if (navButton){
        navButton.classList.add('sq-nav-active');
    }else{
        /* If we're unable to find an element, it means the page hasn't finished rendering yet, so sleep for almost 1 second then try again. */
        const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));
        await sleep(100);
        setActiveNav(page);
    }
}